import React from "react";
import Calculator from "./components/Calculator";
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <Container fluid>
        <Row>
          <Col>
            <Jumbotron>
              <div className="text-center">
                <h1>Calculate if a Company/Stock is Sharia Compliant</h1>
              </div>
              <p>
                1. Open up the balance sheet and income statement of the company
                you want to check.
              </p>
              <small>
                <i>
                  {" "}
                  A quick google of the company name along with investor
                  relations will usually get you to the page you want to then
                  download the PDF version of the reports. e.g Tesco Investor
                  Relations
                </i>
              </small>
            </Jumbotron>
          </Col>
        </Row>
        <Row>
          <Col>
            <Calculator />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
