import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Form, Col, Button, Alert, Badge } from "react-bootstrap";

const Calculator = () => {
  const [ticker, setTicker] = useState(null);
  const [fundamentals, setFundamentals] = useState(null);
  const [stockCompany, setStockCompany] = useState(null);
  const [error, setError] = useState(null);
  const [incomeReport, setIncomeReport] = useState(0);

  // const [nonIslamic, setCurrentSum] = useState(0);
  const [clear, setClear] = useState(false);
  const [clearDebt, setClearDebt] = useState(false);

  const [totalAssets, setTotalAssets] = useState(undefined);
  const [nonIslamic, setNonIslamic] = useState(undefined);
  const [revenue, setRevenue] = useState(undefined);
  const [nonIslamicResult, setNonIslamicResult] = useState(undefined);
  // const [nonIslamicInvestments, setNonIslamicInvestments] = useState(undefined);
  // const [
  //   nonIslamicInvestmentsResult,
  //   setNonIslamicInvestmentsResult
  // ] = useState(undefined);
  const [totalHaramDebt, setTotalHaramDebt] = useState(undefined);
  const [haramDebtResult, setHaramDebtResult] = useState(undefined);

  const fmpBase = "https://financialmodelingprep.com/api/v3";
  const balanceSheet = "/balance-sheet-statement";
  const profile = "/profile";
  const incomeStatement = "/income-statement";
  const limit = "1";
  const period = "quarter";
  const apiToken = "apikey=302d4e26e0737b0357b40d7f817074af";
  // const apiToken = "apikey=302d4e26e0737b0357b40d7f817074af";
  const [haramItems, setHaramItems] = useState([]);
  const [haramItemsDebt, setHaramItemsDebt] = useState([]);
  const AddDebt = (e) => {
    e.preventDefault();
    if (clear) setClearDebt(false);
    let currentNum = document.querySelector("#numDebt").value;
    let itemName = document.querySelector("#itemDebt").value;
    if (currentNum === "" || itemName === "") return;
    
    let obj = {
      name: itemName,
      value: currentNum
    };
    setHaramItemsDebt((oldArray) => [...oldArray, obj]);

    document.querySelector("#numDebt").value = "";
    document.querySelector("#itemDebt").value = "";
  };

  const ClearDebt = (e) => {
    e.preventDefault();
    document.querySelector("#numDebt").value = "";
    document.querySelector("#itemDebt").value = "";
    setHaramItemsDebt([]);
    setTotalHaramDebt(0);
  };
  const Add = (e) => {
    e.preventDefault();
    if (clear) setClear(false);
    let currentNum = document.querySelector("#num").value;
    let itemName = document.querySelector("#item").value;
    if (currentNum === "" || itemName === "") return;
    let obj = {
      name: itemName,
      value: currentNum
    };
    setHaramItems((oldArray) => [...oldArray, obj]);

    document.querySelector("#num").value = "";
    document.querySelector("#item").value = "";
  };

  const Clear = (e) => {
    e.preventDefault();
    document.querySelector("#num").value = "";
    document.querySelector("#item").value = "";
    setHaramItems([]);
    setNonIslamic(0);
  };

  const fetchData = async (e) => {
    e.preventDefault();
    setFundamentals(null);
    setStockCompany(null);
    setError(null);
    const fundamentalsRequest = `${fmpBase}${balanceSheet}/${
      ticker && ticker.toUpperCase()
    }?limit=${limit}&period=${period}&${apiToken}`;
    try {
      const fundamentalsResponse = await Axios.get(fundamentalsRequest);
      if (fundamentalsResponse.data.length < 1) {
        throw new Error("Stock not found");
      }
      const filteredFundRes = fundamentalsResponse.data.filter(
        (el) => el.finalLink !== ""
      );
      const chooseOne =
        filteredFundRes.length < 1
          ? fundamentalsResponse.data
          : filteredFundRes;
      setFundamentals(chooseOne[0]);
      setTotalAssets(chooseOne[0].totalAssets);
    } catch (e) {
      setError(e);
    }
    const stockCompanyRequest = `${fmpBase}${profile}/${ticker}?${apiToken}`;
    try {
      const stockCompanyResponse = await Axios.get(stockCompanyRequest);
      if (stockCompanyResponse.data.length < 1) {
        throw new Error("Stock not found");
      }
      setStockCompany(stockCompanyResponse.data[0]);
    } catch (e) {
      setError(e);
    }
    const incomeReq = `${fmpBase}${incomeStatement}/${
      ticker && ticker.toUpperCase()
    }?limit=${limit}&period=${period}&${apiToken}`;
    try {
      const incomeRes = await Axios.get(incomeReq);
      if (incomeRes.data.length < 1) {
        throw new Error("Stock not found");
      }
      const filteredincomeRes = incomeRes.data.filter(
        (el) => el.finalLink !== ""
      );
      const pickOne =
        filteredincomeRes.length < 1 ? incomeRes.data : filteredincomeRes;
      setIncomeReport(pickOne[0]);
      setRevenue(pickOne[0].revenue);
    } catch (e) {
      setError(e);
    }
  };

  const handleAssetChange = (event) => {
    setTotalAssets(event.target.value);
  };

  const handleRevenueChange = (event) => {
    setRevenue(event.target.value);
  };

  const ResultDisplay = (
    amount,
    moreThanOrLessThan = "<",
    valueForRule = 0
  ) => {
    const rulePassed =
      moreThanOrLessThan === "<"
        ? amount < valueForRule
        : amount > valueForRule;
    const colour = rulePassed ? "success" : "danger";
    return (
      <Alert className="text-center" variant={colour}>
        {isFinite(amount) ? Math.round(amount) : "Check your input "}%
      </Alert>
    );
  };

  useEffect(() => {
    let nonIslamicTotal = Object.values(haramItems).reduce(
      (t, { value }) => t + parseInt(value, 10),
      0
    );
    setNonIslamic(nonIslamicTotal);
    setNonIslamicResult((nonIslamicTotal / revenue) * 100);
  }, [haramItems, nonIslamic, revenue]);

  useEffect(() => {
    let totalHaramDebtz = Object.values(haramItemsDebt).reduce(
      (t, { value }) => t + parseInt(value, 10),
      0
    );
    setTotalHaramDebt(totalHaramDebtz);
    setHaramDebtResult((totalHaramDebtz / totalAssets) * 100);
  }, [haramItemsDebt, totalHaramDebt, totalAssets, haramDebtResult]);

  return (
    <div>
      <h2 className="text-info">Stock Info</h2>
      <hr />
      <form onSubmit={fetchData}>
        {/* <Alert variant="warning">
          <small>Enter stock below to prefill total assets &amp; revenue</small>
        </Alert> */}
        <Form.Row>
          <Col xs="12" md="6">
            {!stockCompany ? null : (
              <Form.Row>
                <Col>
                  <p>
                    {stockCompany && (
                      <img
                        alt="company logo"
                        width="50"
                        src={stockCompany.image}
                      />
                    )}
                    <Badge>{stockCompany.companyName}</Badge> |{" "}
                    <Badge>{stockCompany.sector}</Badge> |{" "}
                    <Badge>{stockCompany.industry}</Badge>
                    <br />
                    Price: <Badge variant="primary">
                      {stockCompany.price}
                    </Badge>{" "}
                    DCF Value:
                    <Badge
                      variant={
                        stockCompany.dcf < stockCompany.price
                          ? "success"
                          : "danger"
                      }
                    >
                      {stockCompany.dcf}
                    </Badge>
                    {fundamentals && fundamentals.finalLink && (
                      <>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={fundamentals && fundamentals.finalLink}
                        >
                          official balance statement
                        </a>
                      </>
                    )}
                    {incomeReport && incomeReport.finalLink && (
                      <>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={incomeReport && incomeReport.finalLink}
                        >
                          official income statement
                        </a>
                      </>
                    )}
                  </p>
                </Col>
              </Form.Row>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            {/* <Form.Row>
              <Col xs="auto">
                <Form.Group controlId="formTicker">
                  <Form.Label>Stock Ticker</Form.Label>
                  <Form.Control
                    onChange={(e) => setTicker(e.target.value)}
                    type="text"
                    placeholder="Symbol"
                  />
                </Form.Group>
              </Col>
              <Col xs="auto" className="align-self-end">
                <Button type="submit" className="mb-3">
                  Find
                </Button>
              </Col>
              {!stockCompany && (
                <Form.Row>
                  <Col>
                    <Form.Group controlId="formCompany">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the company name"
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              )}
            </Form.Row> */}
            {error && (
              <Form.Row>
                <Col>
                  <Form.Group>
                    <div className="badge badge-danger text-wrap">
                      {error.message}
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
            <Form.Group controlId="formAssets">
              <Form.Label>
                2. Enter the Total Assets from the Balance Sheet
              </Form.Label>
              <input
                type="number"
                onChange={handleAssetChange}
                value={totalAssets}
                className="form-control"
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </form>
      
      <br />
      <h2 className="text-info">Financial Check</h2>
      <hr />
      <h4>Interest Bearing Debt must be {"<"} 33%</h4>
      <p>
        3. Add up all interest bearing liabilities from the Balance Sheet.
        <small>
          The total debt of the investee company should not exceed 33% of the
          total assets. The debt here includes all interest-based debt &
          interest based financing.
        </small>
      </p>
      <form>
        <div className="form-group row">
          <div className="col-sm-5">
            <input
              className="form-control"
              type="text"
              id="itemDebt"
              placeholder="Interest Item"
            />
            <Form.Text className="text-muted">
              
            </Form.Text>
          </div>
          {/* <Form.Label>Insert Total Revenue (Income Statement)</Form.Label> */}
          <div className="col-sm-5">
            <input
              className="form-control"
              type="number"
              id="numDebt"
              placeholder="Enter the amount"
            />
          </div>
          <div className="col-sm-2">
            <Button onClick={AddDebt}>Add</Button>
          </div>
        </div>
      </form>
      <table className="table table-striped table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {haramItemsDebt.map((item) => (
            <tr key={item.name + item.value}>
              <th scope="row">{item.name}</th>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total Interest Bearing Debt</th>
            <th>{totalHaramDebt}</th>
          </tr>
        </tfoot>
      </table>
      <Button variant="danger" onClick={ClearDebt}>
        Clear Table
      </Button>
      <br />
      <br />
      <Form.Row>
        {/* <Col xs="12" md="6">
          <Form.Group>
            <Form.Label>Total Interest Bearing Liabilities</Form.Label>
            <input
              type="number"
              onChange={handleHaramTotalDebt}
              defaultValue={totalHaramDebt}
              className="form-control"
            />
            <Form.Text className="text-info">
              Total Assets: {totalAssets}
            </Form.Text>
          </Form.Group>
        </Col> */}
        <Col xs="12">{ResultDisplay(haramDebtResult, "<", 33)}</Col>
      </Form.Row>

      <h2 className="text-info">Business Activity Check</h2>
      <hr />
      <h4>Haram Income must be {"<"} 5% of total revenue</h4>
      <p>
        4. Add up any interest related or haram earnings found in the Income
        Statement
      </p>
      {/* <Col xs="12" md="12"> */}
      <Form.Group controlId="totalRevenue">
        <Form.Label>
          a) First insert Total Revenue (Income Statement)
        </Form.Label>
        <input
          type="number"
          onChange={handleRevenueChange}
          defaultValue={revenue}
          className="form-control"
        />
        {/* <Form.Text className="text-muted"></Form.Text> */}
      </Form.Group>
      <p>
        b) Now go through each line in the income statement and add the haram
        items
      </p>
      {/* </Col> */}

      <form>
        <div className="form-group row">
          <div className="col-sm-5">
            <input
              className="form-control"
              type="text"
              id="item"
              placeholder="Haram Item"
            />
            <Form.Text className="text-muted">
              e.g interest income, gambling, alcohol, pork
            </Form.Text>
          </div>
          {/* <Form.Label>Insert Total Revenue (Income Statement)</Form.Label> */}
          <div className="col-sm-5">
            <input
              className="form-control"
              type="number"
              id="num"
              placeholder="Enter the amount"
            />
          </div>
          <div className="col-sm-2">
            <Button onClick={Add}>Add</Button>
          </div>
        </div>
      </form>
      <table className="table table-striped table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {haramItems.map((item) => (
            <tr key={item.name + item.value}>
              <th scope="row">{item.name}</th>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total haram earnings</th>
            <th>{nonIslamic}</th>
          </tr>
        </tfoot>
      </table>
      <Button variant="danger" onClick={Clear}>
        Clear Table
      </Button>
      <br />
      <br />
      <Form.Row>
        {/* <Col xs="12" md="6">
          <Form.Group controlId="nonIslamicRevenue">
            <Form.Label>Interest related or haram earnings total:</Form.Label>
            <p className="font-weight-bold">{nonIslamic}</p>
            <button onClick={Clear}>Clear</button>
          </Form.Group>
        </Col> */}

        <Col>{ResultDisplay(nonIslamicResult, "<", 5)}</Col>
      </Form.Row>
      {/* <h4>Haram Investments (Assets) must be {"<"} 33% of total assets</h4>
      <p>
        Any investments into Haram assets. Check Balance Sheet & Income
        Statement
      </p>
      <Form.Row>
        <Col xs="12" md="12">
          <Form.Group controlId="nonIslamicRevenue">
            <Form.Label>Non-Islamic Investments</Form.Label>
            <input
              type="number"
              onChange={handleNonIslamicInvestmentsChange}
              defaultValue={nonIslamicInvestments}
              className="form-control"
            />
            <Form.Text className="text-muted">
              e.g bonds, cash at the bank etc
            </Form.Text>
            <Form.Text className="text-info">
              Total Assets: {totalAssets}
            </Form.Text>
          </Form.Group>
        </Col>

        <Col>{ResultDisplay(nonIslamicInvestmentsResult, "<", 33)}</Col>
      </Form.Row> */}
    </div>
  );
};

export default Calculator;
